<template>
  <div class="pointsMall_container">
    <div v-if="adimg" class="img" @click="handleJump(info.adv_url)">
      <img :src="adimg" alt="" />
    </div>
    <div class="content">
      <div class="title">当前积分商城商品</div>
      <div class="type-box">
        <span :class="[activeInd == index ? 'active-title' : '']" v-for="(item, index) in listType" :key="index" @click="changeType(index)">{{ item.title }}</span>
      </div>
      <div class="active-title-text">{{ activeTitle }}</div>
      <div class="goods_wrap" v-if="list.length > 0">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="goods_img">
            <img :src="item.logo" alt="" />
          </div>
          <div class="flex_d_c_c">
            <div class="title-box c3 f16 text-overflow2">{{ item.title }}</div>
            <p class="jifen">兑换积分：{{ item.points }}分</p>
            <p class="c9">剩余数量：{{ item.number }}</p>
            <div class="btn flex_c_c c_p" :class="[item.can_exchange == 101 ? 'green' : 'grey']" @click="handleExchange(item)">
              {{ item.btn_str }}
            </div>
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>

    <el-dialog title="积分兑换" :visible.sync="dialogVisible" width="50%">
      <!-- label-width="80px" -->
      <el-form :model="form" label-width="100px">
        <el-form-item label="兑换商品：">
          <span>{{ goodsTitle }}</span>
        </el-form-item>
        <el-form-item label="所需积分：">
          <span>{{ points }}</span>
        </el-form-item>
        <el-form-item label="收货人：">
          <el-input v-model="form.cname" placeholder="收货人" class="w300" />
        </el-form-item>
        <el-form-item label="收货电话：">
          <el-input v-model="form.lxdh" placeholder="收货电话" class="w300" />
        </el-form-item>
        <el-form-item label="收货地址：">
          <div v-if="addressList.length">
            <el-select v-model="form.address_id" placeholder="请选择" @change="getDeliverymethod" style="width: 300px">
              <el-option v-for="item in addressList" :key="item.id" :label="item.address" :value="item.id"> </el-option>
            </el-select>
            <el-button plain @click="$router.push('/index/subRouter/address')" class="add-btn"> 去添加地址 </el-button>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { handleJump, getZoneInfo } from "@/utils";

export default {
  data() {
    return {
      handleJump,
      listType: [],
      list: [],
      dialogVisible: false,
      goodsTitle: "",
      form: {
        cname: "",
        lxdh: "",
        // shdh: "",
        id: "", //积分商品id
        address_id: "", //第十id
      },
      info: {},
      points: 0,
      addressList: [],
      listArr: [],
      activeInd: 0,
      typeId: "",
      adimg: "",
      activeTitle: "",
    };
  },
  created() {
    this.getADV();
    this.getDataType();
    // this.getData();
    this.$api("cart.getAddress", {}).then((res) => {
      let defaultAddress = res.data.filter((v) => {
        return v.is_default == 101;
      });
      this.addressList = res.data;
      // console.log("地址列表----", this.addressList);
      // console.log("默认地址----", defaultAddress[0]);
      this.form.cname = defaultAddress[0].cname;
      this.form.lxdh = defaultAddress[0].lxdh;
      this.form.address_id = defaultAddress[0].id;
    });
    getZoneInfo(this.$route.query.value).then((res) => {
      this.info = res;
    });
  },

  methods: {
    getADV() {
      this.$api("zone.toolsBanner", { type: 3 }).then((res) => {
        if (res.code == 200) {
          this.adimg = res.data.logo;
        }
      });
    },
    getDataType() {
      this.$api("zone.getPointsType").then((res) => {
        this.listType = res.data;
        this.typeId = this.listType[0].id;
        this.activeTitle = this.listType[0].title;
        this.getData(this.typeId);
        // this.getData();
      });
    },
    getData() {
      this.$api("zone.getPoints").then((res) => {
        this.listArr = res.data;
        this.list = this.listArr[0].list;
      });
    },
    changeType(ind) {
      this.activeInd = ind;
      this.activeTitle = this.listType[ind].title;
      // this.list = data.list;
      this.list = this.listArr[ind].list;
      // this.getData(data.id);
    },
    handleExchange(item) {
      if (item.can_exchange == 102) return;
      if (item.type == 2) {
        this.$alert("确定要兑换吗？", "兑换商品", {
          confirmButtonText: "确定",
          callback: () => {
            this.$api("zone.ExchangePoints", { id: item.id }).then((res) => {
              if (res.code == 200) {
                this.dialogVisible = false;
                this.$message.success("兑换成功");
              }
              if (res.code == 201) {
                this.$message.error(res.desc);
              }
            });
          },
        });
      } else {
        this.goodsTitle = item.title;
        this.form.id = item.id;
        this.points = item.points;
        this.dialogVisible = true;
      }
    },
    getDeliverymethod(e) {
      console.log("选中的值---", e);
      let defaultAddress = this.addressList.filter((v) => {
        return v.id == e;
      });
      this.form.cname = defaultAddress[0].cname;
      this.form.lxdh = defaultAddress[0].lxdh;
      this.form.address_id = defaultAddress[0].id;
    },
    submit() {
      console.log(this.form);
      if (this.form.address_id == "") {
        this.$message.warning("请选择收货地址");
        return;
      }
      this.$alert("确定要兑换吗？", "兑换商品", {
        confirmButtonText: "确定",
        callback: () => {
          this.$api("zone.ExchangePoints", this.form).then(() => {
            this.dialogVisible = false;
            this.getData();
            this.$message.success("兑换成功");
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.pointsMall_container {
  background-size: cover;
  // background-color: #7ab9e5;
}
.w300 {
  width: 300px;
}
.img {
  width: 100%;
  height: 375px;
  // padding-bottom: 10px;
}
.title {
  width: 100%;
  height: 58px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-left: 20px;
  line-height: 58px;
  box-sizing: border-box;
  margin: 10px 0;
}
.type-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 22px;
  margin-top: 20px;
  padding-bottom: 20px;
  // margin-bottom: 20px;
  border-bottom: 2px solid #ff9800;
  color: #000000;
  cursor: pointer;
  .active-title {
    color: #e85c1b;
  }
}
.active-title-text {
  width: 100%;
  text-align: center;
  padding: 30px 0;
  font-size: 24px;
  font-weight: bold;
}
.goods_wrap {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 230px;
    // height: 370px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 10px;
    box-sizing: border-box;
    &:nth-child(5n) {
      margin-right: 0;
    }
    .goods_img {
      width: 210px;
      height: 200px;
      background: #ffffff;
      border-radius: 4px;
    }
    .text-overflow2 {
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .title-box {
      height: 46px;
      width: 100%;
      // background-color: #ff9800;
      margin-top: 10px;
    }
    .jifen {
      font-size: 16px;
      font-weight: bold;
      color: #f93232;
      margin: 14px 0;
    }
    .btn {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      color: #fff;
      margin-top: 15px;
    }
    .green {
      background: @themeColor;
    }
    .grey {
      background-color: #eee;
      color: #333;
    }
  }
}
.add-btn {
  margin-left: 10px;
}
</style>
